
<div *ngIf="gtinData" class="container">

  <div class="col-md-12 col-s-12 col-xs-12">
    <div class="bg-ui-white">
      <span class="back-to-search-result"> <a (click)="onNavigationClick()">&lt; {{'Back_to_search_results' |
          translate}}</a> </span>
    </div>
    <div  #matTabGroup>
      <app-gtin-header [gtinAttribute]=gtinData [arrErrorMessages]=arrErrorMessages keyType='gtin'
        [canDisplayAttributesSection]=canDisplayAttributesSection>></app-gtin-header>
    </div>
   
  </div>
  <mat-tab-group  mat-align-tabs="start" [selectedIndex]="tabIndex" (selectedTabChange)="onTabChanged(matTabGroup);">
    <mat-tab label="{{this.ProductInfo_Label | translate}}" *ngIf="gtinData.canDisplayAttributesSection || gtinData.linkset">
      <div *ngIf="gtinData.productDescription">
        <app-gtinattributes [gtinAttribute]=gtinData [arrErrorMessages]=arrErrorMessages
          [canDisplayAttributesSection]=canDisplayAttributesSection></app-gtinattributes>
      </div>
      <div>
        <app-gtinl2sd [gtinAttribute]=gtinData *ngIf="gtinData.linkset"></app-gtinl2sd>
      </div>
    </mat-tab>
    <mat-tab label="{{this.ComapnyInfo_Label | translate}}">
      <div>
        <app-gtincompanyinfo [gtinAttribute]=gtinData *ngIf="gtinData.gs1Licence"></app-gtincompanyinfo>
      </div>     
    </mat-tab>
  </mat-tab-group>
</div>


<div *ngIf="glnData" class="container">

  <div class="col-md-12 col-s-12 col-xs-12">
    <div class="bg-ui-white">
      <span class="back-to-search-result"> <a (click)="onNavigationGlnClick()">&lt; {{'Back_to_search_results' |
          translate}}</a> </span>
    </div>
    <div  #matTabGroup>
      <app-gtin-header [glnAttribute]=glnData [arrErrorMessages]=arrErrorMessages keyType='gln'
        [canDisplayAttributesSection]=canDisplayAttributesSection>></app-gtin-header>
    </div>
   
  </div>
  <mat-tab-group  mat-align-tabs="start" [selectedIndex]="tabIndex" (selectedTabChange)="onTabChanged(matTabGroup);">
    <mat-tab label="{{this.ProductInfo_Label | translate}}" *ngIf="(glnData.canDisplayAttributesSection && hasGlnInfo) || glnData.linkset">
      <div>
        <app-gtinattributes [glnAttribute]=glnData [arrErrorMessages]=arrErrorMessages
          [canDisplayAttributesSection]=canDisplayAttributesSection></app-gtinattributes>
      </div>
      
    </mat-tab>
    <mat-tab label="{{this.ComapnyInfo_Label | translate}}">
      <div>
        <app-gtincompanyinfo [gtinAttribute]=glnData *ngIf="glnData?.gs1Licence"></app-gtincompanyinfo>
      </div>     
    </mat-tab>
  </mat-tab-group>
</div>

<div *ngIf="otherKeysData" class="container">

  <div class="col-md-12 col-s-12 col-xs-12">
    <div class="bg-ui-white">
      <span class="back-to-search-result"> <a (click)="onNavigationOtherKeysClick()">&lt; {{'Back_to_search_results' |
          translate}}</a> </span>
    </div>
    <div  #matTabGroup>
      <app-gtin-header [glnAttribute]=otherKeysData [arrErrorMessages]=arrErrorMessages keyType='otherkeys'
        [canDisplayAttributesSection]=false>></app-gtin-header>
    </div>
   
  </div>
  <mat-tab-group  mat-align-tabs="start" [selectedIndex]="tabIndex" (selectedTabChange)="onTabChanged(matTabGroup);">
    
    <mat-tab label="{{this.ComapnyInfo_Label | translate}}">
      <div>
        <app-gtincompanyinfo [gtinAttribute]=otherKeysData *ngIf="otherKeysData?.gs1Licence"></app-gtincompanyinfo>
      </div>      
    </mat-tab>

    <mat-tab  *ngIf="otherKeysData && otherKeysData?.linkset " label="{{this.Additional_Information | translate}}">
      <div>
        <app-otherKeysl2sd [otherKeyAttribute]=otherKeysData ></app-otherKeysl2sd>
      </div>      
    </mat-tab>

  </mat-tab-group>

</div>

<div *ngIf="companyData" class="container">

  <div class="col-md-12 col-s-12 col-xs-12">
    <div class="bg-ui-white">
      <span class="back-to-search-result"> <a (click)="onNavigationCompanesClick()">&lt; {{'Back_to_search_results' |
          translate}}</a> </span>
    </div>
    <div  #matTabGroup>
      <app-gtin-header [glnAttribute]=glnData [arrErrorMessages]=arrErrorMessages
        [canDisplayAttributesSection]=canDisplayAttributesSection>></app-gtin-header>
    </div>   
  </div>
  <mat-tab-group  mat-align-tabs="start" [selectedIndex]="tabIndex" (selectedTabChange)="onTabChanged(matTabGroup);">
    <mat-tab label="{{this.ProductInfo_Label | translate}}" *ngIf="companyData.canDisplayAttributesSection && hasGlnInfo">
      <div>
        <app-gtinattributes [glnAttribute]=companyData [arrErrorMessages]=arrErrorMessages
          [canDisplayAttributesSection]=canDisplayAttributesSection></app-gtinattributes>
      </div>    
    </mat-tab>
    <mat-tab label="{{this.ComapnyInfo_Label | translate}}">
      <div>
        <app-gtincompanyinfo [gtinAttribute]=companyData *ngIf="companyData?.gs1Licence"></app-gtincompanyinfo>
      </div>    
    </mat-tab>
  </mat-tab-group>
</div>