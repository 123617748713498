import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthCallbackComponent } from 'src/auth-callback/auth-callback.component';
import { AuthGuard } from 'src/guards/auth.guard';
import { AppComponent } from './app.component';
import { GtinDetailComponent } from './components/gtin-detail/gtin-detail/gtin-detail.component';
import { GtinComponent } from './components/gtin/gtin.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';


const routes: Routes = [

  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: GtinComponent,
    canActivate: [AuthGuard],
    children:[
      {
        path:'gtin-detail',
        component:GtinDetailComponent,
    
      },
    ]
  },
  {
    path:'dashboard',
    component:AppComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'auth-callback',
    component: AuthCallbackComponent
  },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '', redirectTo: '/404', pathMatch: 'full' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
