<div class="deskContent bg-ui-1 bg-white">
  <div class="container">
    <div fxLayout="row" class="fxLayout-container">
      <div style="flex:1" class="header-logo">
        <div fxLayout="row">
          <div fxLayout.lt-md="column">
            <a  (click)="redirectToCustomURL()" class="header-logo-image">
              <img class="logo-img" alt="GS1 logo" [src]="header_image_url">
            </a>
            <a href="/home" style="text-decoration: none;">
              <span class="header-logo-text">{{'Header_Title' | translate}}</span>
            </a>
          </div>
        </div>
      </div>


      <div class="header-menu" style="padding-top: 1%; ">
        <button mat-flat-button [disableRipple]="true" (click)="clickSupportLink()">
          <span class="menu-color">{{'Support' | translate}}</span>
        </button>
      </div>

      <div class="header-menu" style="padding-top: 1%;">
        <button mat-flat-button [disableRipple]="true" [matMenuTriggerFor]="language">
          <span class="menu-color">{{user_defaultlanguage | translate}}</span>
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>

        <mat-menu #language="matMenu">
          <button mat-menu-item *ngFor="let acc of user_languages" (click)="clickLanguageMenuItem(acc)">
            <span class="menu-color">{{acc | translate}}</span>
          </button>
        </mat-menu>
      </div>

      <div class="header-logout">
        <button class="header-logout-button" color="primary" (click)="logout()">{{'Logout' | translate}}</button>
      </div>
    </div>
  </div>
</div>