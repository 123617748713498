
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class HeaderComponent  {
  @Input() displayBanner: boolean;

  @Output() languageChanged: EventEmitter<string> = new EventEmitter<string>();

  updateTextAndErrorMessages(event)
  {
    this.languageChanged.emit(event);
  }
}
