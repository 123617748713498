<div class="bg-ui-1">

  <div>
    <app-header id="app-header" [displayBanner]="true" (languageChanged)="updateGPCTextAndErrorMessages()">
    </app-header>
  </div>

  <div class="background-cover">
    <div class="container" style="min-width: 1140px;">
      <h1 class="pt-4 pb-4 fw-normal">
        <img src="../../../assets/img/check-green.png" class="mr-10px">
        {{'Header_Title' | translate}}
      </h1>
      <div class="col-md-12 col-s-12 col-xs-12">
        <div class="verified-search-form">
          <div class="card-wrapper">
            <app-card style="width: 24%;" *ngFor="let card of cards; let i = index" [imageSrc]="card.imageSrc"
              [title]="card.title" [subtitle]="card.subtitle" [isSelected]="i === selectedCardIndex"
              (cardClicked)="onCardClicked(i,card.placeHolder)"></app-card>
          </div>

          <div *ngIf="selectedCardIndex == 3" style="text-align: right;" class="optional-fields-button"
            (click)="showOptional = !showOptional">
            <button class="optionalButton"><img width="20px" height="20px"
                [src]="showOptional ? '../../../assets/img/minus.svg' :'../../../assets/img/plus.svg'">{{ showOptional ?
              ('Hide_Optional_Fields' | translate) : ('Show_Optional_Fields' | translate) }}</button>
          </div>
          <div class="searchcontentdirection">
            <div class="col-md-12" [ngStyle]="{
            'display': (selectedCardIndex == 0 || selectedCardIndex == 1) ? 'block' : 'flex',
            'justify-content': (selectedCardIndex == 0 || selectedCardIndex == 1) ? 'initial' : 'space-between'
          }">
              <select-dropdown (selectedItemEvent)="OnSelectedItemChanged($event)" [options]="GS1KeyTypes"
                [placeHolder]="('Select_A_GS1_key_Type' | translate)" *ngIf="currentCard == 'Search_For_Upto_500_GS1_Keys' " style="width: 48%;">
              </select-dropdown>
              <select-dropdown (selectedItemEvent)="OnSelectedItemChanged($event)" [options]="countryList"
                [placeHolder]="('Select_A_Country' | translate)" *ngIf="currentCard == 'Enter_A_Company_Name'"
                style="width: 48%;">
              </select-dropdown>
              <!-- <h3 class="mb-spacer-4">
              {{'Verify_a_products_identity' | translate}}
            </h3> -->
              <div class="search-text-container" [ngStyle]="{
              'width': (selectedCardIndex == 0 || selectedCardIndex == 1) ? '100%' : '48%'
            }">
                <app-search-text *ngIf="selectedCardIndex == 0" [search]="searchGtins"
                  [placeHolder]="('Search_For_Upto_500_GTINS' | translate)"
                  (searchTextEvent)="OnSearchGTINClick($event)"></app-search-text>
                <app-search-text *ngIf="selectedCardIndex == 1" [search]="searchGlns"
                [placeHolder]="('Search_For_Upto_500_GLNS' | translate)" (searchTextEvent)="OnSearchGlnClick($event)">
                </app-search-text>
                <app-search-text *ngIf="selectedCardIndex == 2" [search]="searchGtins" [placeHolder]="('Search_For_Upto_500_GS1_Keys' | translate)"
                  (searchTextEvent)="OnSearchOtherKeysClick($event)"></app-search-text>
                <app-search-text *ngIf="selectedCardIndex == 3" [search]="searchGtins"
                  [placeHolder]="('Enter_A_Company_Name' | translate)"
                  (searchTextEvent)="OnSearchCompanyClick($event)">
                </app-search-text>
              </div>
            </div>

            <!-- <div class="col-md-3 offset-md-1">
            <div fxLayout="row">
              <span class="pr-spacer-2"><img src="{{cdnUrl}}/check-circle-fill.svg" alt=""></span>
              <span class="label-medium">{{'Data_only_provided_by' | translate}} </span><br>
            </div>
            <img class="barcodeimg" mat-card-image src="{{cdnUrl}}/barcodes-tool.png" alt="Barcode">
          </div> -->
          </div>
          <div *ngIf="showOptional && selectedCardIndex == 3" class="optionaFieldsWrapper">
            <mat-form-field appearance="outline" class="searchMatFormField" style="border-color: red;">
              <mat-icon matPrefix>search</mat-icon>
              <mat-label style="font-size: 15px;">{{"Street_Address" | translate}}
              </mat-label>
              <input matInput [(ngModel)]="street" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="searchMatFormField" style="border-color: red;">
              <mat-icon matPrefix>search</mat-icon>
              <mat-label style="font-size: 15px;">{{"Zip/Postal_Code" | translate}}
              </mat-label>
              <input matInput [(ngModel)]="postalcode" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="searchMatFormField" style="border-color: red;">
              <mat-icon matPrefix>search</mat-icon>
              <mat-label style="font-size: 15px;">{{"City_Optional" | translate}}
              </mat-label>
              <input matInput [(ngModel)]="city" />
            </mat-form-field>        
          </div>
        </div>

        <div *ngIf="(gtinCount==1 || gtinCount>500 || gtinCount == 0)">
          <div #matTabGroup>
            <app-gtin-header [gtinAttribute]=gtinAttribute [arrErrorMessages]=arrErrorMessages keyType="gtin"
              (searchTextEvent)="OnSearchGTINClick($event)" [canDisplayAttributesSection]=canDisplayAttributesSection
              [CheckdigitErrorMessage]=CheckdigitErrorMessage>
            </app-gtin-header>
          </div>
          <mat-tab-group mat-align-tabs="start" [selectedIndex]="0" #tabToggleRef appHideMe
            (selectedTabChange)="onTabChanged(matTabGroup);">
            <div *ngIf="arrErrorMessages.length==0">
              <mat-tab label="{{this.ProductInfo_Label | translate}}"
                *ngIf="!tabToggleRef.hide || gtinAttribute?.linkset">
                <div *ngIf="gtinAttribute && gtinAttribute.productDescription">
                  <app-gtinattributes [gtinAttribute]=gtinAttribute [arrErrorMessages]=arrErrorMessages
                    [canDisplayAttributesSection]=canDisplayAttributesSection></app-gtinattributes>
                </div>
                <div *ngIf="gtinAttribute && gtinAttribute.linkset">
                  <app-gtinl2sd [gtinAttribute]=gtinAttribute *ngIf="gtinAttribute?.linkset "></app-gtinl2sd>
                </div>
              </mat-tab>
              <mat-tab label="{{this.ComapnyInfo_Label | translate}}"
                *ngIf="gtinAttribute && gtinAttribute?.gs1Licence ">
                <app-gtincompanyinfo [gtinAttribute]=gtinAttribute>
                </app-gtincompanyinfo>
              </mat-tab>
            </div>
          </mat-tab-group>
        </div>

        <div *ngIf="(glnCount==1 || glnCount>500 || glnCount == 0)">
          <div #matTabGroup>
            <app-gtin-header [glnAttribute]=glnAttribute [arrErrorMessages]=arrErrorMessages keyType="gln"
              (searchTextEvent)="OnSearchGlnClick($event)" [canDisplayAttributesSection]=canDisplayAttributesSection
              [CheckdigitErrorMessage]=CheckdigitErrorMessage>
            </app-gtin-header>
          </div>
          <mat-tab-group mat-align-tabs="start" [selectedIndex]="0" #tabToggleRef appHideMe
            (selectedTabChange)="onTabChanged(matTabGroup);">
            <div *ngIf="arrErrorMessages.length==0">
              <mat-tab label="{{this.ProductInfo_Label | translate}}"
                *ngIf="glnAttribute  && (!tabToggleRef.hide || glnAttribute?.linkset)">
                <div *ngIf="glnAttribute">
                  <app-gtinattributes [glnAttribute]=glnAttribute [arrErrorMessages]=arrErrorMessages
                    [canDisplayAttributesSection]=canDisplayAttributesSection></app-gtinattributes>
                </div>
              </mat-tab>
              <mat-tab label="{{this.ComapnyInfo_Label | translate}}" *ngIf="glnAttribute && glnAttribute?.gs1Licence ">
                <app-gtincompanyinfo [gtinAttribute]=glnAttribute>
                </app-gtincompanyinfo>
              </mat-tab>
            </div>
          </mat-tab-group>
        </div>

        <div *ngIf="(otherKeyCount==1 || otherKeyCount>500 || otherKeyCount == 0 )">
          <div #matTabGroup>
            <app-gtin-header [gtinAttribute]=otherKeyAttribute [arrErrorMessages]=arrErrorMessages keyType="otherKeys"
              (searchTextEvent)="OnSearchOtherKeysClick($event)" [canDisplayAttributesSection]=canDisplayAttributesSection
              [CheckdigitErrorMessage]=CheckdigitErrorMessage>
            </app-gtin-header>
          </div>
          <mat-tab-group mat-align-tabs="start" [selectedIndex]="0" #tabToggleRef appHideMe
            (selectedTabChange)="onTabChanged(matTabGroup);">
            <div *ngIf="arrErrorMessages.length==0">
              
              <mat-tab label="{{this.ComapnyInfo_Label | translate}}"
                *ngIf="otherKeyAttribute && otherKeyAttribute?.gs1Licence ">
                <app-gtincompanyinfo [gtinAttribute]=otherKeyAttribute>
                </app-gtincompanyinfo>
              </mat-tab>

              <mat-tab label="{{this.Additional_Information | translate}}"
              *ngIf="otherKeyAttribute && otherKeyAttribute?.linkset ">
              <app-otherKeysl2sd [otherKeyAttribute]=otherKeyAttribute>
              </app-otherKeysl2sd>
            </mat-tab>
            </div>
          </mat-tab-group>
        </div>

        <div *ngIf="arrErrorMessages?.length>0 && showCompanyList">
          <div  [ngClass]="{'row-header-error': arrErrorMessages.length>0 }">    
          <div class="error-container" fxLayout="row">
            <div>
              <ng-container *ngFor="let error of arrErrorMessages">
                <p  class="label-error-data" >{{error}}</p>
              </ng-container>
                <p class="label-info-message" style="font-size: 0.95rem;color: #262626;" >{{'Please_check_the_typos_or' |
                  translate}} <a target="_blank" class="toulink" (click)="clickSupportLink()">{{'contact_GS1' |
                    translate}}</a> {{'for_additional_support' | translate}}</p>
            </div> 
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="container" *ngIf="dataSource && gtinCount>1" [hidden]="!showList">
    <div class="tileViewWrap">
      <div class="search-text-container"
        style="min-height: 200px; background-color: white; padding: 38px 79px;">
        <div *ngIf="errorWithMultiSearchCount>0" fxLayout="row" class="row-header-error">
          <div fxFlex="90%">
            <div fxLayout="row">
              <div>
                <p class="label-error-data">{{'Some_of_your_search_terms_had_errors' | translate}}
                  ({{'Count' | translate}}: {{errorWithMultiSearchCount}})</p>
                <p style="font-size: 1rem !important;">{{'batch_error_message' | translate}}</p>
              </div>
              <br>
            </div>
          </div>
        </div>
        <br>
        <div>
          <div style="padding-top: 2%; margin-bottom: 18px;">
            <mat-label class="searchresult-label">
              {{'Search_results' | translate}}
            </mat-label>
            <div>
              <span style="float: right;">
                <button class="export-button" color="primary" (click)="exportSearchResult()">
                  {{'Export' | translate}}
                </button>
              </span>
              <!-- <span style="float: right; padding-right: 5%;">
                <button class="export-button" [disabled]="disablePDFButton" color="primary" id="btnPDF"
                  (click)="downloadPDF()">
                  {{'Print_PDF' | translate}}
                </button>
              </span> -->
            </div>
          </div>
          <div>
            <mat-label class="moredetails-label">
              {{'Click_on_the_GTIN_to_see_more_details' | translate}}
            </mat-label>
          </div>
        </div>
        <div>
          <mat-form-field appearance="outline" class="filterListView">
            <input placeholder="{{ 'Search' | translate }}" matInput (keyup)="applyFilter($event.target.value)"
              ngModel={{filterValues.productionDescription}} />
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>

          <mat-checkbox class="filterCheckBoxSpacing" (change)="applyFilterIsComplete($event)"
            [checked]="filterValues.isComplete">
            <div class="filterIsCompleted">{{'Show_only_products_with_complete_attributes' | translate}}</div>
          </mat-checkbox>
        </div>
      </div>

      <div class="mat-elevation-z8">
        <mat-table #table [dataSource]="dataSource " matSort class="mat-cell" (matSortChange)="sortChange($event)">
          <ng-container matColumnDef="ProductImage">
            <mat-header-cell *matHeaderCellDef class="mat-header-cell-style"><strong></strong></mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div class="product-image-box product-image-box-fit">
                <a *ngIf="!row.validationErrors && row.productImageUrl " mattooltip="Test" class="mat-tooltip-trigger"
                  ng-reflect-message="Home" aria-describedby="cdk-describedby-message-1" cdk-describedby-host="">
                  <img alt="Image cannot be displayed" src={{row.productImageUrl[0].value}}
                    onerror="this.onerror=null;this.src='../../../../assets/img/image_not_displayed.png';">
                </a>
              </div>

              <div class="product-image-box product-image-box-fit"
                *ngIf="row.validationErrors || !row.productDescription || !row.productImageUrl">
                <img src="{{cdnUrl}}/image_not_displayed.png" alt="Not available">
              </div>
            </mat-cell>

            <mat-cell *matCellDef="let row">
              <div *ngIf="row.validationErrors || !row.productDescription"><img style="height: 125px;"
                  src="{{cdnUrl}}/image_not_displayed.png" alt="Not available"></div>

            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="gtin">
            <mat-header-cell *matHeaderCellDef class="mat-header-cell-style" mat-sort-header><strong
                #GTINColumn>GTIN</strong></mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span class="mobile-label">GTIN</span>
              <a *ngIf="!row.validationErrors" class="quick-access-link ListviewWorkBreak"
                (click)="redirectToDetails(row)">{{row.gtin}}</a>
              <div class="error-row-cell" *ngIf="row.validationErrors">{{row.gtin}}</div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="gln">
            <mat-header-cell *matHeaderCellDef class="mat-header-cell-style" mat-sort-header><strong
                #GLNColumn>GLN</strong></mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span class="mobile-label">GLN</span>
              <a *ngIf="!row.validationErrors" class="quick-access-link ListviewWorkBreak"
                (click)="redirectToDetails(row)">{{row.gln}}</a>
              <div class="error-row-cell" *ngIf="row.validationErrors">{{row.gln}}</div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef class="mat-header-cell-style" mat-sort-header><strong
                #ProductDescriptionColumn>{{'Product_description' |
                translate}}</strong>
            </mat-header-cell>
            <ng-container *matCellDef="let row">


              <mat-cell class="descriptionValue"
                *ngIf="!row.validationErrors && row.productDescription  else display_not_available">
                <span class="mobile-label">{{'Product_description' |
                  translate}}</span>

                <div class="ListviewWorkBreak">{{row.description}}</div>
              </mat-cell>

              <mat-cell class="descriptionValue" *ngIf="!row.productDescription && !row.validationErrors">
                <span class="mobile-label">{{'Product_description' |
                  translate}}</span>
                {{dataSource.data[0].licenseeName}}
              </mat-cell>

              <mat-cell *ngIf="row.validationErrors">
                <span class="mobile-label">{{'Product_description' |
                  translate}}</span>
                <div class="error-row-cell">
                  <ul>
                    <div *ngFor="let error of row.validationErrors[0].arrErrorMessages">
                      <li>{{error}}</li>
                    </div>
                  </ul>
                </div>
              </mat-cell>

            </ng-container>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

          <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'error-row': row.validationErrors }">
          </mat-row>

        </mat-table>

        <mat-paginator #paginator [pageIndex]="0" [pageSize]="10" [showFirstLastButtons]="true"
          [pageSizeOptions]="[10, 20, 50, 100]">
        </mat-paginator>
      </div>

      <br>

      <br />
    </div>
  </div>
  <div class="container" *ngIf="dataSource && glnCount>1" [hidden]="!showGlnList">
    <div class="tileViewWrap">
      <div class="search-text-container"
        style="min-height: 200px; background-color: white; padding: 38px 79px;">
        <div *ngIf="errorWithMultiSearchCount>0" fxLayout="row" class="row-header-error">
          <div fxFlex="90%">
            <div fxLayout="row">
              <div>
                <p class="label-error-data">{{'Some_of_your_search_terms_had_errors' | translate}}
                  ({{'Count' | translate}}: {{errorWithMultiSearchCount}})</p>
                <p style="font-size: 1rem !important;">{{'You_Can_Continue_Or_Try_Again_With_Valid_GLNs' | translate}}</p>
              </div>
              <br>
            </div>
          </div>
        </div>
        <br>
        <div>
          <div style="padding-top: 2%;  margin-bottom: 18px;">
            <mat-label class="searchresult-label">
              {{'Search_results' | translate}}
            </mat-label>
            <div>
              <span style="float: right;">
                <button class="export-button" color="primary" (click)="exportSearchResult()">
                  {{'Export' | translate}}
                </button>
              </span>              
            </div>
          </div>
          <div>
            <mat-label class="moredetails-label">
              {{'Click_on_the_GLN_to_see_more_details' | translate}}
            </mat-label>
          </div>
        </div>
        <div>
          <mat-form-field appearance="outline" class="filterListView">
            <input placeholder="{{ 'Search' | translate }}" matInput (keyup)="applyFilter($event.target.value)"
              ngModel={{filterValues.description}} />
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>


        </div>
      </div>

      <div class="mat-elevation-z8">
        <mat-table #table [dataSource]="dataSource " matSort class="mat-cell" (matSortChange)="sortChange($event)">

          <ng-container matColumnDef="gln">
            <mat-header-cell *matHeaderCellDef class="mat-header-cell-style" mat-sort-header><strong
                #GLNColumn>GLN</strong></mat-header-cell>
            <mat-cell [ngStyle]="{'max-width': (row.validationErrors) ? '34%' : '100%'}" *matCellDef="let row">
              <span class="mobile-label">GLN</span>
              <a *ngIf="!row.validationErrors" class="quick-access-link ListviewWorkBreak"
                (click)="redirectToGlnDetails(row)">{{row.gln}}</a>
              <div class="error-row-cell" *ngIf="row.validationErrors">{{row.gln}}</div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="glnTypeLabel">
            <mat-header-cell *matHeaderCellDef class="mat-header-cell-style" mat-sort-header>
              <strong #GLNColumn>{{'GLN_Type' | translate}}</strong></mat-header-cell>
            <mat-cell [ngStyle]="{'max-width': (row.validationErrors) ? '0px' : '100%'}" *matCellDef="let row">

              <span class="mobile-label">GLN</span>
              <span class="mobile-label" style=" width: 100%; " *ngIf="!row.validationErrors"
                class=" ListviewWorkBreak">{{row.glnTypeLabel}}</span>
            </mat-cell>

          </ng-container>

          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef class="mat-header-cell-style" mat-sort-header><strong
                #ProductDescriptionColumn>{{'GLN_Description' |
                translate}}</strong>
            </mat-header-cell>
            <ng-container *matCellDef="let row">

              <mat-cell class="descriptionValue" *ngIf="!row.validationErrors && row.organizationName ">
                <span class="mobile-label">{{'Product_description' |
                  translate}}</span>

                <div class="ListviewWorkBreak">{{row.organizationName[0].value + ' ' + row.endOfDescription}}</div>
              </mat-cell>

              <mat-cell class="descriptionValue" *ngIf="!row.validationErrors && row.department ">
                <span class="mobile-label">{{'Product_description' |
                  translate}}</span>

                <div class="ListviewWorkBreak">{{row.department[0].value + ' ' + row.endOfDescription}}</div>
              </mat-cell>

              <mat-cell class="descriptionValue" *ngIf="!row.validationErrors && row.digitalLocationName">
                <span class="mobile-label">{{'Product_description' |
                  translate}}</span>

                <div class="ListviewWorkBreak">{{row.digitalLocationName[0].value + ' ' + row.endOfDescription}}</div>
              </mat-cell>

              <mat-cell class="descriptionValue" *ngIf="!row.validationErrors && row.physicalLocationName ">
                <span class="mobile-label">{{'Product_description' |
                  translate}}</span>

                <div class="ListviewWorkBreak">{{row.physicalLocationName[0].value + ' ' + row.endOfDescription}}</div>
              </mat-cell>



              <mat-cell *ngIf="row.validationErrors">
                <span class="mobile-label">{{'Product_description' |
                  translate}}</span>
                <div class="error-row-cell">
                  <ul>
                    <div *ngFor="let error of row.validationErrors[0].arrErrorMessages">
                      <li>{{error}}</li>
                    </div>
                  </ul>
                </div>
              </mat-cell>

            </ng-container>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedGlnColumns"></mat-header-row>

          <mat-row *matRowDef="let row; columns: displayedGlnColumns;" [ngClass]="{'error-row': row.validationErrors }">
          </mat-row>

        </mat-table>

        <mat-paginator #paginator [pageIndex]="0" [pageSize]="10" [showFirstLastButtons]="true"
          [pageSizeOptions]="[10, 20, 50, 100]">
        </mat-paginator>
      </div>

      <br>

      <br />
    </div>
  </div>
  <div class="container" *ngIf="dataSource && otherKeyCount>1" [hidden]="!showOtherKeyList">
    <div class="tileViewWrap">
      <div class="search-text-container"
        style="min-height: 200px; background-color: white; padding: 38px 79px;">
        <div *ngIf="errorWithMultiSearchCount>0" fxLayout="row" class="row-header-error">
          <div fxFlex="90%">
            <div fxLayout="row">
              <div>
                <p class="label-error-data">{{'Some_of_your_search_terms_had_errors' | translate}}
                  ({{'Count' | translate}}: {{errorWithMultiSearchCount}})</p>
                <p style="font-size: 1rem !important;">{{'You_Can_Continue_Or_Try_With_Valid_GS1_Keys' | translate}}</p>
              </div>
              <br>
            </div>
          </div>
        </div>
        <br>
        <div>
          <div style="padding-top: 2%; margin-bottom: 18px;">
            <mat-label class="searchresult-label">
              {{'Search_results' | translate}}
            </mat-label>
            <div>
              <span style="float: right;">
                <button class="export-button" color="primary" (click)="exportSearchResult()">
                  {{'Export' | translate}}
                </button>
              </span>              
            </div>
          </div>
          <div>
            <mat-label class="moredetails-label">
              {{'Click_On_GS1_Keys_To_See_More' | translate}}
            </mat-label>
          </div>
        </div>
        <div>
          <mat-form-field appearance="outline" class="filterListView">
            <input placeholder="{{ 'Search' | translate }}" matInput (keyup)="applyFilter($event.target.value)"
              ngModel={{filterValues.description}} />
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>


        </div>
      </div>

      <div class="mat-elevation-z8">
        <mat-table #table [dataSource]="dataSource " matSort class="mat-cell" (matSortChange)="sortChange($event)">
          <ng-container matColumnDef="OtherKeyType">
            <mat-header-cell *matHeaderCellDef class="mat-header-cell-style" mat-sort-header>
              <strong #GLNColumn>{{'GS1_Key_Value' | translate}}</strong></mat-header-cell>
            <mat-cell [ngStyle]="{'max-width': (row.validationErrors) ? '34%' : '100%'}" *matCellDef="let row">
              <span class="mobile-label">{{'GS1_Key_Value' | translate}}</span>
              <a *ngIf="!row.validationErrors" class="quick-access-link ListviewWorkBreak"
                (click)="redirectToOtherKeyDetails(row)">{{row[selectedKey] ? row[selectedKey] :
                row[selectedKey?.toUpperCase()]}}</a>
              <div class="error-row-cell" *ngIf="row.validationErrors">{{row[selectedKey] ? row[selectedKey] :
                row[selectedKey?.toUpperCase()]}}</div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="OtherKeyLicenceName">
            <mat-header-cell *matHeaderCellDef class="mat-header-cell-style" mat-sort-header>
              <strong #GLNColumn>{{'Company_Name' | translate}}</strong>
            </mat-header-cell>
            <mat-cell [ngStyle]="{'max-width': (row.validationErrors) ? '0px' : '100%'}" *matCellDef="let row">

              <span class="mobile-label">{{'Company_Name' | translate}}</span>
              <span class="mobile-label" style=" width: 100%; word-break: break-word; padding-right: 5px" *ngIf="!row.validationErrors"
                class=" ListviewWorkBreak">{{row.gs1Licence?.licenseeName}}</span>
            </mat-cell>

          </ng-container>

          <ng-container matColumnDef="LicensingMO">
            <mat-header-cell *matHeaderCellDef class="mat-header-cell-style" mat-sort-header><strong
                #ProductDescriptionColumn>{{'GS1_Licensing_MO' |
                translate}}</strong>
            </mat-header-cell>
            <ng-container *matCellDef="let row">

              <mat-cell class="descriptionValue" *ngIf="!row.validationErrors && row.gs1Licence?.licensingMO?.moName ">
                <span class="mobile-label">{{'GS1_Licensing_MO' |
                  translate}}</span>

                <div class="ListviewWorkBreak">{{row.gs1Licence?.licensingMO?.moName}}</div>
              </mat-cell>

              <mat-cell *ngIf="row.validationErrors">
                <span class="mobile-label">{{'GS1_Licensing_MO' |
                  translate}}</span>
                <div class="error-row-cell">
                  <ul>
                    <div *ngFor="let error of row.validationErrors[0].arrErrorMessages">
                      <li *ngIf="error!=' E039contactGS1'"  >{{error}}</li>
              <li *ngIf="error==' E039contactGS1'" class="label-info-message" >{{'Please' |
                translate}} <a target="_blank" class="toulink" (click)="clickSupportLink()">{{'contact_GS1' |
                  translate}}</a> {{'for_further_assistance' | translate}}</li>

                    </div>
                  </ul>
                </div>
              </mat-cell>

            </ng-container>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedOtherKeyColumns"></mat-header-row>

          <mat-row *matRowDef="let row; columns: displayedOtherKeyColumns;"
            [ngClass]="{'error-row': row.validationErrors }">
          </mat-row>

        </mat-table>

        <mat-paginator #paginator [pageIndex]="0" [pageSize]="10" [showFirstLastButtons]="true"
          [pageSizeOptions]="[10, 20, 50, 100]">
        </mat-paginator>
      </div>

      <br>

      <br />
    </div>
  </div>


  <div class="container" *ngIf="dataSource && companySearchKeyword!='' " [hidden]="!showCompanyList">
    <div class="tileViewWrap">
      <div class="search-text-container"
        style="min-height: 200px; background-color: white; padding: 38px 79px;">
        <div class="verified-company">
          <div style="margin-right: 0;" class="row" [ngClass]="{'row-header-completed': companyCount>0 && arrErrorMessages?.length==0,
          'row-header-completed': companyCount>0 , 
          'row-header-error': arrErrorMessages?.length>0 }">
            <p style="font-size: 18px;font-weight: 700;">{{('Your_Search_For' | translate) + " " + companySearchKeyword+ (countrySearchKey!='ALL'? " " + ('In' | translate) + " "+ getCountryCodeByName(countrySearchKey):'')+ " " + ('Returned' | translate) + " " + companyCount+ " " + ('Results' | translate)}}
            </p>
            <p *ngIf="companyCount > 100" style="font-size: 18px;font-weight: 700; margin-top: 0 !important;"> {{ 'This_Service_Show_First_100_Records' | translate}}</p>
          </div>
        </div>
        <div>
          <div style="padding-top: 2%; margin-bottom: 18px;">
            <mat-label class="searchresult-label">
              {{'Search_results' | translate}}
            </mat-label>            
          </div>
          <div>
            <mat-label class="moredetails-label">
              {{'Click_On_The_Company_To_See_More_Details' | translate}}
            </mat-label>
          </div>
        </div>       
      </div>

      <div style="padding: 0 79px; background-color: white;" *ngIf="dataSource && companyCount>0">
        <mat-table #table [dataSource]="dataSource " matSort class="mat-cell" (matSortChange)="sortChange($event)">
          <ng-container matColumnDef="licenceKey">
            <mat-header-cell *matHeaderCellDef class="mat-header-cell-style" mat-sort-header><strong
                #licenceKeyColumn>{{'CI_LicenceKey' | translate}}</strong></mat-header-cell>
            <mat-header-cell *matHeaderCellDef class="mat-header-cell-style" mat-sort-header><strong
                #licenceKeyColumn>{{'LicenceKey' | translate}}</strong></mat-header-cell>
            <mat-cell [ngStyle]="{'max-width': (row.validationErrors) ? '200px' : '100%'}" *matCellDef="let row">
              {{row.licenceKey}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="licenseeName">
            <mat-header-cell *matHeaderCellDef class="mat-header-cell-style" mat-sort-header><strong
                #licenseeNameColumn>{{'CI_CompanyName' | translate}}</strong>
            </mat-header-cell>
            <mat-cell [ngStyle]="{'max-width': (row.validationErrors) ? '200px' : '100%'}" *matCellDef="let row">
              <span class="mobile-label"> {{'licenseeName' | translate}}</span>
              <a *ngIf="!row.validationErrors" class="quick-access-link ListviewWorkBreak"
                (click)="redirectToCompanyDetails(row)">{{row.licenseeName}}</a>
              <div class="error-row-cell" *ngIf="row.validationErrors">{{row.licenseeName}}</div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="addressLocality">
            <mat-header-cell *matHeaderCellDef class="mat-header-cell-style" mat-sort-header>
              <strong #cityColumn>{{'City' | translate}}</strong>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row.address?.addressLocality?.value }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="countryCode">
            <mat-header-cell *matHeaderCellDef class="mat-header-cell-style" mat-sort-header><strong
                #countryColumn>{{'Country' | translate}}</strong></mat-header-cell>
            <mat-cell *matCellDef="let row">{{ getCountryCodeByName(row?.address?.countryCode) }}</mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedCompanyColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedCompanyColumns;"
            [ngClass]="{'error-row': row.validationErrors }">
          </mat-row>
        </mat-table>
        <mat-paginator #paginator [pageIndex]="0" [pageSize]="10" [showFirstLastButtons]="true"
          [pageSizeOptions]="[10, 20, 50, 100]">
        </mat-paginator>
      </div>

      <br>

      <br />
    </div>
  </div>  
  <router-outlet></router-outlet>
</div>